//한글폰트 Spoqa Han Sans
@font-face {
    font-family: "Spoqa Han Sans";
    font-weight: 200;
    src: url("./fonts/SpoqaHanSansNeo-Thin.woff2") format("woff2"),
    url("./fonts/SpoqaHanSansNeo-Thin.woff") format("woff"),
    url("./fonts/SpoqaHanSansNeo-Thin.ttf") format("ttf")
}

@font-face {
    font-family: "Spoqa Han Sans";
    font-weight: 300;
    src: url("./fonts/SpoqaHanSansNeo-Light.woff2") format("woff2"),
    url("./fonts/SpoqaHanSansNeo-Light.woff") format("woff"),
    url("./fonts/SpoqaHanSansNeo-Light.ttf") format("ttf")
}

@font-face {
    font-family: "Spoqa Han Sans";
    font-weight: 400;
    src: url("./fonts/SpoqaHanSansNeo-Regular.woff2") format("woff2"),
    url("./fonts/SpoqaHanSansNeo-Regular.woff") format("woff"),
    url("./fonts/SpoqaHanSansNeo-Regular.ttf") format("ttf")
}

@font-face {
    font-family: "Spoqa Han Sans";
    font-weight: 600;
    src: url("./fonts/SpoqaHanSansNeo-Medium.woff2") format("woff2"),
    url("./fonts/SpoqaHanSansNeo-Medium.woff") format("woff"),
    url("./fonts/SpoqaHanSansNeo-Medium.ttf") format("ttf")
}

@font-face {
    font-family: "Spoqa Han Sans";
    font-weight: 800;
    src: url("./fonts/SpoqaHanSansNeo-Bold.woff2") format("woff2"),
    url("./fonts/SpoqaHanSansNeo-Bold.woff") format("woff"),
    url("./fonts/SpoqaHanSansNeo-Bold.ttf") format("ttf")
}

//영문폰트 Roboto
@font-face {
    font-family: "Roboto";
    font-weight: 300;
    src: url("./fonts/Roboto-300.woff2") format("woff2"),
    url("./fonts/Roboto-300.woff") format("woff"),
    url("./fonts/Roboto-300.ttf") format("ttf")
}

@font-face {
    font-family: "Roboto";
    font-weight: 400;
    src: url("./fonts/Roboto-400.woff2") format("woff2"),
    url("./fonts/Roboto-400.woff") format("woff"),
    url("./fonts/Roboto-400.ttf") format("ttf")
}

@font-face {
    font-family: "Roboto";
    font-weight: 500;
    src: url("./fonts/Roboto-500.woff2") format("woff2"),
    url("./fonts/Roboto-500.woff") format("woff"),
    url("./fonts/Roboto-500.ttf") format("ttf")
}

@font-face {
    font-family: "Spoqa Han Sans";
    font-weight: 700;
    src: url("./fonts/Roboto-700.woff2") format("woff2"),
    url("./fonts/Roboto-700.woff") format("woff"),
    url("./fonts/Roboto-700.ttf") format("ttf")
}