@import "./mixin.scss";
@import "./common.scss";
@import "./table.scss";
@import "./font.scss";

//body 전체 wrap
.wrapper {
  padding-top: 50px !important;
}

.contents {
  height: 100vh;
}

.content {
  padding: 0 !important;
}

#kt_content {
  .flex-column-fluid {
    height: auto;
    overflow: hidden;
    // padding-top: 54px;
  }
}

.no-admin {
  #kt_wrapper {
    padding-left: 0;
  }
}

//최초 진입 로그인화면 & 회원가입
.login-root {
  background: #f3f6f9;
}

#kt_login {
  .login-aside {
    .main-logo {
      display: flex;
      flex-direction: row;
      margin-top: 10px;

      .logo-icon {
        width: auto;
        height: 46px;
        margin-right: 6px;
      }

      .logo-txt {
        width: auto;
        height: 42px;
        margin-top: 4px;
      }
    }
  }

  .login-wrap {
    width: 520px;
    height: 100%;
    right: 100px;
    justify-content: center;

    .login-init {
      padding: 120px 0;
      background: #fff;
      box-shadow: 0px 2px 10px 0px rgba(52, 69, 86, 0.1);
      border-radius: 10px;

      .login-form {
        .display3 {
          font-family: "Roboto", sans-serif;
          font-weight: 400;
        }

        .form-group {
          &.login-input {
            input,
            .MuiFormControlLabel-label {
              font-size: 1.4rem;
            }

            label {
              font-size: 13px;
            }
          }

          .is-valid,
          .is-invalid {
            padding-right: 0px;
          }

          .MuiInputBase-root {
            background: transparent !important;

            .MuiInputBase-input {
              background: transparent !important;
            }

            .MuiSelect-icon {
              top: calc(50% - 10px);
              right: 10px;
            }
          }

          .save-id-info {
            label {
              margin-bottom: 0;
              letter-spacing: -0.6px;
              color: #000;
              font-weight: 600;
            }
          }
        }

        .register-wrap {
          display: flex;
          flex-direction: row;
          margin-top: 40px;
          letter-spacing: -0.6px;
          color: #000;
          align-items: center;
          justify-content: center;

          .btn-line-wrap {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

//사이드 바
#kt_aside {
  letter-spacing: -1px;

  .brand {
    padding: 0 20px;
    border-bottom: 1px dashed #dce4ec;

    .brand-logo {
      .brand-logo-icon {
        height: 36px;
      }

      .brand-logo-txt {
        height: 36px;
        margin-top: 1px;
        margin-left: 8px;
      }
    }
  }

  #kt_aside_menu {
    .menu-nav {
      .menu-item-open {
        .menu-toggle {
          &::before {
            content: "";
            position: absolute;
            left: 0;
            width: 3px;
            height: 100%;
            background: #3693ff;
          }
        }

        .menu-submenu {
          .menu-subnav {
            .menu-item {
              &.menu-item-active {
                .menu-link {
                  &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    width: 3px;
                    height: 100%;
                    background: #3693ff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//사이드 바 최소화
.aside-minimize {
}

//헤더
.header {
  background: #313949 !important;

  #kt_header_menu {
    .menu-nav {
      align-items: center;

      .find-input {
        .MuiInput-underline {
          width: 200px;
          height: 22px;
          padding: 0 5px;
          border-radius: 20px;
          background: rgba(255, 255, 255, 0.2);
          transition: background-color 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

          &:before {
            border-bottom: none;
          }

          &:after {
            border-bottom: none;
          }

          &:hover {
            &:before {
              border-bottom: none !important;
            }
          }

          &.Mui-focused {
            background: #fff;
            transition: background-color 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

            .MuiInputAdornment-root {
              .MuiSvgIcon-root {
                fill: #1c262e;
                transition: fill 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
              }
            }

            .MuiInput-input {
              color: #1c262e;
            }

            &:before {
              border-bottom: none !important;
            }
          }

          .MuiInputAdornment-root {
            width: 22px;
            height: 22px;
            margin-right: 0;

            .MuiSvgIcon-root {
              width: 16px;
              height: 16px;
              margin: 0 auto;
              fill: rgba(255, 255, 255, 0.6);
              transition: fill 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            }
          }

          .MuiInput-input {
            color: #fff;
            padding: 4px 0 4px;
          }
        }
      }
    }
  }

  .topbar {
    .dropdown {
      align-items: center;

      .topbar-item {
        height: 70%;
        padding: 0 10px;
        letter-spacing: -1px;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background: rgba(0, 0, 0, 0.4);
        }

        .dropdown-profile {
          display: flex;
          align-items: center;

          .profile-belong {
            margin-right: 4px;
            color: #d4d4d4;
            font-size: 11px;
          }

          .profile-name {
            color: #fff;
            font-size: 13px;
          }

          .profile-icon {
            display: flex;
            width: 24px;
            height: 24px;
            margin-left: 10px;
            background: #fff;
            border-radius: 4px;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
}

//대쉬보드 메인 페이지
.dashboard-wrap {
  height: 100%;
  // border-top: 1px solid #edf2f7;
  background: url("./media/images/home-background01.jpg") no-repeat;
  background-size: 90%;
  background-position: center;
}

//Panel 페이지 기본 레이아웃
.page-wrap {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;

  .column-left {
    flex-shrink: 0;

    &.fixed-left {
      width: 480px;
    }

    &.fluid-left {
      width: calc(100% - 400px);
    }

    &.full-size {
      width: 100%;
    }

    &.modal-left {
      width: 35%;
      overflow-y: auto;
      background: #f3f6f9;
      padding: 30px 20px;
      border-left: 1px solid #eaebec;
    }

    &.modal-right {
      width: 65%;
    }

    .panel-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      padding: 0 10px 0;

      .panel-header-init {
        display: flex;
        flex-direction: row;

        .panel-header-tit {
          margin-right: 20px;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: -1px;
        }

        .breadcrumb-list {
          display: flex;
          margin-bottom: 0;
          flex-direction: row;
          align-items: center;

          .breadcrumb-item {
            font-size: 12px;
            letter-spacing: -1px;
            color: #b5b5c2;
            padding-left: 0.3rem;

            i {
              transition: ease;
              transition-property: color;
              transition-duration: 0.3s;

              &:hover {
                color: #3693ff;
                transition: ease;
                transition-property: color;
                transition-duration: 0.3s;
              }
            }

            & + .breadcrumb-item {
              &::before {
                content: "•";
                padding-right: 0.3rem;
              }
            }

            &.current {
              color: #3693ff;
            }
          }
        }
      }
    }

    //GRID 상단 SEARCH LINE
    .grid-search-wrap {
      padding: 10px 20px;
      background-color: #fbfbfb;
      border-top: 1px solid #eaebec;
      border-bottom: 1px solid #eaebec;

      form {
        display: flex;
        align-items: center;

        > .search-group {
          display: flex;
          align-items: center;

          & + .search-group {
            margin-left: 20px;
          }

          label {
            white-space: nowrap;
            color: #52585e;
            font-size: 12px;
            margin-bottom: 0 !important;
            margin-right: 10px;
            letter-spacing: -1px;
          }

          .dx-field {
            width: 100px;
            margin-bottom: 0;

            .search-group {
              label {
                margin-right: 0;
              }
            }
          }

          .to-mark {
            margin-left: 10px;
          }

          .search-select {
            width: 180px;
            height: 26px;
            border: 1px solid #ddd;
            border-radius: 4px;
          }
        }

        button {
          margin-left: 20px;
        }
      }

      &.fixed-left {
        form {
          display: flex;
          justify-content: space-between;

          .search-group {
            input {
              width: 110px;
            }

            & + .search-group {
              margin-left: 0px;
            }
          }

          button {
            margin-left: 0px;
          }
        }
      }
    }

    //정산관리 좌우분리 레이아웃
    .grid-template-row {
      display: flex;
      width: 100%;
      height: calc(100vh - 168px);
      padding: 10px;
      gap: 10px;
    }

    //정산관리 상하분리 레이아웃
    .grid-template-column {
      display: flex;
      height: calc(100vh - 168px);
      padding: 10px;
      flex-direction: column;
      gap: 10px;

      .grid-component {
        &:first-child {
          height: 60%;
        }

        &:last-child {
          height: 40%;
        }
      }

      &.half-layout {
        > div {
          &:first-child {
            height: calc(50% - 50px);
          }

          &:last-child {
            height: calc(50% + 50px);
          }

          .grid-tap-wrap {
          }

          .inner-grid-component {
            display: flex;
            height: 100%;
            gap: 10px;

            .column-right {
              height: calc(100% - 40px);
              border-top: 1px solid #eaebec;
              border-right: 1px solid #eaebec;
              border-bottom: 1px solid #eaebec;

              .form-container {
                height: 100%;
                justify-content: space-between;

                .search-group {
                  height: 100%;

                  div {
                    height: 100%;

                    textarea {
                      height: 100%;
                    }
                  }
                }
              }
            }
          }

          .grid-component {
            height: calc(100% - 40px);
          }
        }
      }
    }

    //오더 페이지 기능버튼 set
    .function-btn-wrap {
      display: flex;
      height: 45px;
      padding: 0 20px;
      align-items: center;
      border-bottom: 1px solid #eaebec;

      button + button {
        margin-left: 10px;
      }

      .seperate {
        width: 1px;
        height: 24px;
        margin: 0 20px;
        border-left: 1px dotted #dadcdf;
      }
    }

    .costmy-grid-wrap {
      display: flex;
      flex-direction: column;
      height: calc(100% - 87px);
      max-height: 40vh;

      .total-count {
        text-align: right;
        margin-top: 5px;
        margin-right: 10px;
      }
    }

    //메인 DataGrid
    .grid-component {
      display: flex;
      height: calc(100% - 87px);
      padding: 5px 10px 10px;
      flex-direction: column;

      &.no-toolbar {
        height: calc(100%);
        padding: 0;
      }

      &.treelist {
        padding-top: 10px;
      }

      &.has-toolbar {
        position: relative;
        padding-top: 5px;

        .grid-button-group {
          position: absolute;
          display: flex;
          left: 40px;
          gap: 4px;
          z-index: 10;
        }

        .grid-total {
          position: absolute;
          top: 5px;
          right: 10px;
        }
      }

      &.has-func-btn {
        height: calc(100% - 132px) !important;
      }

      &.has-multi-func-btn {
        height: calc(100% - 178px) !important;
      }

      .grid-total {
        display: flex;
        height: 30px;
        font-size: 12px;
        align-items: center;
        justify-content: flex-end;
      }

      .datagrid-wrap {
        overflow: auto;
        height: 100%;

        .dx-datagrid {
          background: none;

          td {
            border-color: #e0e0e0 !important;
          }

          .dx-header-filter::before {
            display: inline-block;
            content: "";
            width: 14px;
            height: 14px;
            background: url("./icons/sort_24dp.svg");
            background-size: 14px 14px;
          }

          .dx-datagrid-headers {
            color: #fff;
            background: #1e4575;
            border: none;

            .dx-header-row {
              td {
                text-align: center !important;
                vertical-align: middle;
              }
            }
          }

          .dx-row-focused {
            &.dx-data-row {
              td {
                background: #ff9114;
                color: #fff;

                .dx-datagrid-group-closed,
                .dx-datagrid-group-opened {
                  color: #fff;
                }
              }
            }
          }

          .dx-master-detail-cell {
            .dx-row-focused {
              &.dx-data-row {
                td {
                  background: #ff9114;
                  color: #fff;

                  .dx-datagrid-group-closed,
                  .dx-datagrid-group-opened {
                    color: #fff;
                  }
                }
              }
            }
          }

          .dx-toolbar {
            background: none;

            .dx-toolbar-items-container {
              .dx-toolbar-after {
                width: 100%;
                padding: 0;
              }
            }
          }

          .dx-master-detail-row {
            .dx-datagrid-headers {
              background: #90949e;
            }

            .dx-datagrid-rowsview {
              background: #fff;
            }
          }

          .dx-freespace-row {
            // background: #e0e0e0;

            td {
              border-color: #fff !important;
            }
          }

          .dx-cell-focus-disabled.dx-master-detail-cell {
            background: #e0e0e0;
            // background-color: rgb(247, 247, 248);
          }
        }

        //Tree-type 그리드
        .dx-treelist-container {
          .dx-treelist-headers {
            color: #fff;
            background: #1e4575;
            border: none;
          }

          .dx-treelist-rowsview {
            border-top: none;
          }

          .dx-header-row {
            text-align: center !important;
          }
        }
      }
    }
  }

  .column-right {
    overflow-y: auto;
    background: #f3f6f9;
    padding: 30px 20px;
    border-left: 1px solid #eaebec;

    &.fluid-right {
      width: calc(100% - 480px);

      .form-container {
        .form-field {
          display: grid;
          width: 100%;
          grid-template-columns: 1fr 1fr 2fr;
          grid-column-gap: 10px;

          .search-group {
            & + .search-group {
              margin-top: 0px;
            }
          }
        }
      }
    }

    &.fixed-right {
      width: 400px;
    }

    .form-container {
      display: flex;
      width: 100%;
      box-sizing: border-box;
      flex-direction: column;
      align-items: center;
      gap: 12px;

      .divider-line {
        height: 1px;
        width: 100%;
        margin: 3px 0;
        background: #e2e5e7;
        // background: #e4e4e4;
      }

      .form-tit {
        width: 100%;
        font-size: 16px;
        letter-spacing: -1px;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .form-row {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 2%;

        &.row-button {
          align-items: flex-end;

          .search-group {
            width: calc(98% - 26px);
          }
        }

        .search-group {
          width: 49%;

          & + .search-group {
            margin-top: 0;
          }
        }
      }

      .toggle-row {
        width: 100%;
        padding: 5px 0px;
        background: #fdfdfd;
        border: 1px solid #ddd;
        border-radius: 2px;

        .search-group {
          label {
            display: none;
          }

          .dx-collection {
            display: flex;
            justify-content: center;
            gap: 10px;
          }

          .dx-item-content {
            line-height: 18px;
          }
        }
      }

      .form-grid {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
      }

      .search-group {
        width: 100%;

        label {
          margin-bottom: 3px;
          font-size: 12px;
          letter-spacing: -1px;

          &.required {
            &::after {
              content: " *";
              font-size: 16px;
              line-height: 0px;
              vertical-align: middle;
              color: #d33a3a;
            }
          }
        }

        .search-inputbox {
          input {
            padding: 4px 5px;
            width: 100%;
          }
        }

        .dx-checkbox-container {
          .dx-checkbox-text {
            margin-left: unset;
            letter-spacing: -1px;
          }
        }

        &.address {
          > div + div {
            margin-top: 5px;
          }
          .postal-code {
            display: grid;
            grid-template-columns: 3fr 2fr;
            grid-column-gap: 5px;
          }
        }

        .input-file-wrap {
          display: flex;
          gap: 5px;

          .file-tit {
            display: flex;
            padding: 0 5px;
            background: #eee;
            border: 1px solid #ddd;
            border-radius: 4px;
            align-items: center;
            flex-grow: 1;
          }

          .file-btn-wrap {
            display: flex;
            gap: 2px;
          }
        }
      }

      .subgrid {
        width: 100%;
        margin-top: 10px;
        background: none;

        .dx-datagrid {
          background: none;

          .dx-toolbar {
            background: none;
          }

          .dx-datagrid-headers {
            color: #fff;
            background: #1d3350;
          }

          .dx-datagrid-rowsview {
            background: #fff;
            border-top: none;
          }
        }

        .dx-treelist-container {
          background: none;

          .dx-treelist-header-panel {
            height: 10px;

            .dx-toolbar {
              background: none;
            }
          }

          .dx-treelist-headers {
            color: #fff;
            background: #1d3350;
          }

          .dx-treelist-rowsview {
            background: #fff;
            border-top: none;
          }
        }
      }
    }
  }

  .datagrid-btn {
    width: 100%;
    padding: 0;
    cursor: pointer;
  }

  .datagrid-zip {
    display: flex;
    align-items: center;
    width: 100%;
    span {
      width: 80%;
    }
    button {
      width: auto;
    }
  }

  // // 메뉴페이지
  // .menuPage {
  //   // 버튼
  //   .dx-link {
  //     height: 22px !important;
  //     width: 40px !important;
  //     margin: 0 auto !important;
  //     border: none !important;
  //     color: #fff !important;
  //     background-color: #566c8a !important;

  //     &:hover {
  //       border: none !important;
  //       background-color: rgb(52, 62, 73) !important;
  //     }

  //     &:active {
  //       border: none !important;
  //       background-color: #3e444b !important;
  //     }
  //   }
  // }
}

.order-page {
  .grid-total {
    right: 10px !important;
  }
}

// 세금계산서 발행 폼
.taxissue-form {
  display: table !important;
  position: relative;
  box-sizing: border-box;
  font-size: 12px;
  border-color: #a1a1ab;
  border-top: 2px solid #dedede;
  border-bottom: 2px solid #dedede;

  .taxissue-table {
    font-size: 13px;
    clear: both;
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
    word-break: break-all;
    border-spacing: 0;

    .search-group {
      display: block !important;

      label {
        display: none !important;
      }
    }

    colgroup {
      col {
        width: calc(100% / 34);
      }
    }

    input {
      width: 100%;
      min-height: 20px;
    }

    input[type="radio"] {
      display: inline-block;
      width: 13px;
      height: 13px;
      line-height: 1;
      vertical-align: baseline;
      cursor: pointer;
    }

    textarea {
      width: 100%;
      height: 100%;
      min-height: 35px;
      overflow: hidden;
      padding: 0 2px;
      margin: 0;
      // border: 1px solid #ced4da;
      border-radius: 2px;
      background-color: #fff;
      font-size: 12px;
      vertical-align: top;
      resize: none;
    }

    .email {
      height: 24px !important;
    }

    td,
    th {
      border: 1px solid #dedede;
      border-top-width: 0;
      border-left-width: 0;
      padding: 3px;
      height: 31px;

      &.bt {
        border-top-width: 2px;
        border-top-color: #a1a1ab;
      }
    }

    th {
      background-color: #f3f5f4;
      font-weight: normal;
      color: #474747;
    }

    thead {
      .header-tit {
        font-weight: 500;
      }
    }

    th {
      &.invoicer {
        border-bottom-color: #f9b2c4;
        border-right-color: #f9b2c4;
        background-color: #fef4f5;
        color: #f03347;
        &.bt {
          border-top-width: 2px;
          border-top-color: #f9b1b4;
        }
        &.title {
          background-color: #ffc4ca;
          color: #ff2c4c;
          font-weight: bold;
        }
      }

      &.invoicee {
        border-bottom-color: #b1c8f2;
        border-right-color: #b1c8f2;
        background-color: #e9f3ff;
        color: #526b93;
        &.bt {
          border-top-width: 2px;
          border-top-color: #b0ccfe;
        }

        &.title {
          background-color: #c4dcff;
          color: #3465b2;
          font-weight: bold;
        }
      }
    }

    td {
      &.invoicer {
        &.bt {
          border-top-width: 2px;
          border-top-color: #f9b1b4;
        }
      }

      &.invoicee {
        &.bt {
          border-top-width: 2px;
          border-top-color: #b0ccfe;
        }
      }
    }

    .box-container {
      display: inline-block;
      width: 100px;
      position: relative;
      border-radius: 3px;
      background-color: #eef2f7;
    }
  }

  .readonly {
    background-color: #eaeaea !important;
    textarea {
      background-color: #eaeaea !important;
    }
  }
  .valienm {
    vertical-align: middle !important;
  }
  .a_c {
    text-align: center !important;
  }
  .a_l {
    text-align: left !important;
  }
  .a_r {
    text-align: right !important;
  }
  .bb0 {
    border-bottom-width: 0 !important;
  }
  .br0 {
    border-right-width: 0 !important;
  }
  .pr4 {
    padding-right: 4px !important;
  }
  .f22 {
    font-size: 22px !important;
  }
  .lh22 {
    line-height: 22px !important;
  }
  .lh35 {
    line-height: 35px !important;
  }
  .ls0 {
    letter-spacing: 0px !important;
  }

  .form-s {
    line-height: 20px;
  }
}

// 거래명세서 -> 세금계산서에서 조금 변경
.tradeset {
  font-size: 13px;
  clear: both;
  width: 100%;
  table-layout: fixed;
  border: 1px solid #ddd;
  border-collapse: collapse;
  word-break: break-all;
  border-spacing: 0;

  .search-group {
    display: block !important;

    label {
      display: none !important;
    }
  }

  colgroup {
    col {
      width: calc(100% / 34);
    }
  }

  input {
    width: 100%;
    min-height: 20px;
  }

  input[type="radio"] {
    display: inline-block;
    width: 13px;
    height: 13px;
    line-height: 1;
    vertical-align: baseline;
    cursor: pointer;
  }

  textarea {
    width: 100%;
    height: 100%;
    min-height: 35px;
    overflow: hidden;
    padding: 0 2px;
    margin: 0;
    // border: 1px solid #ced4da;
    border-radius: 2px;
    background-color: #fff;
    font-size: 12px;
    vertical-align: top;
    resize: none;
  }

  .email {
    height: 24px !important;
  }

  td,
  th {
    border: 1px solid #dedede;
    border-top-width: 0;
    border-left-width: 0;
    padding: 3px;
    height: 31px;

    &.bt {
      border-top-width: 2px;
      border-top-color: #a1a1ab;
    }

    &.input {
      width: 100%;
      & > div {
        display: block;
      }
    }
  }

  th {
    background-color: #f3f5f4;
    font-weight: normal;
    color: #474747;
  }

  thead {
    .header-tit {
      font-weight: 500;
    }
  }

  th {
    &.invoicer {
      // border-bottom-color: #f9b2c4;
      // border-right-color: #f9b2c4;
      // background-color: #fef4f5;
      // color: #f03347;
      &.bt {
        border-top-width: 2px;
        // border-top-color: #f9b1b4;
      }
      &.title {
        // background-color: #ffc4ca;
        // color: #ff2c4c;
        font-weight: bold;
      }
    }

    &.invoicee {
      // border-bottom-color: #b1c8f2;
      // border-right-color: #b1c8f2;
      // background-color: #e9f3ff;
      // color: #526b93;
      &.bt {
        border-top-width: 2px;
        // border-top-color: #b0ccfe;
      }

      &.title {
        // background-color: #c4dcff;
        // color: #3465b2;
        font-weight: bold;
      }
    }
  }

  td {
    &.invoicer {
      &.bt {
        border-top-width: 2px;
        // border-top-color: #f9b1b4;
      }
    }

    &.invoicee {
      &.bt {
        border-top-width: 2px;
        // border-top-color: #b0ccfe;
      }
    }
  }

  .box-container {
    display: inline-block;
    width: 100px;
    position: relative;
    border-radius: 3px;
    background-color: #eef2f7;
  }
}

//태블릿 가로
@include tablet {
}

//모바일 가로-태블릿 세로
#kt_header_mobile {
  .mobile-logo {
    width: 30%;
    path,
    circle,
    text {
      fill: rgba(255, 255, 255, 0.7);
    }

    &:hover {
      path,
      circle,
      text {
        fill: rgba(255, 255, 255, 0.9);
      }
    }
  }
}

@include mobile-lg {
}

//모바일 세로
@include mobile {
}
