//페이지 공통
#kt_content {
  .container {
    max-width: unset;
    padding: 0;
  }

  //CRUD BUTTON GROUP
  .form-btn-group {
    display: flex;
    margin-bottom: 10px;
    flex-direction: row-reverse;
    align-items: center;

    &.single-line {
      padding: 7px 10px;

      .btn-grid {
        height: 32px;
        padding: 0 20px;
      }
    }

    &.double-line {
      height: 111px;

      .button-group {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-right: 10px;

        .btn-grid {
          width: 80px;

          & + .btn-grid {
            margin-left: 0;
            margin-top: 10px;
          }
        }
      }
    }

    .button-only-group {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;

      & > div {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
      }

      .seperate {
        display: flex;
        width: 1px;
        height: 80%;
        margin: 0 30px;
        border-left: 1px dashed #dce4ec;
      }
    }
  }

  //신규등록 FORM CONTAINER
}

//BUTTON SET
.btn {
  letter-spacing: -1px;
  cursor: pointer;
  border-radius: 2px !important;

  &:focus {
    border-width: 2px;
    border-color: #337ab7;
  }

  &.btn-login {
    width: 100%;
    color: #fff;
    font-size: 18px;
    letter-spacing: 0px;
    background: #3693ff;

    &:hover {
      color: #fff;
      background: #2c84e9;
    }

    &:active {
      color: #fff;
      background: #2579d8;
    }
  }

  &.btn-logout {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 16px;

    svg {
      margin-right: 5px;
      fill: #3694ff;
    }

    &:hover {
      background: #e2e2e2;
    }

    &:active {
      background: #d6d6d6;
    }
  }

  &.btn-grid-search {
    width: 60px;
    min-width: 40px;
    height: 24px;
    padding: 0;
    font-size: 12px;
    color: #fff;
    background: #565e66;

    &:hover {
      color: #fff;
      background: #454c53;
    }

    &:active {
      background: #373e44;
    }
  }

  &.btn-grid {
    width: 60px;
    height: 26px;
    padding: 0;
    color: #fff;
    font-size: 12px;
    white-space: nowrap;

    & + .btn-grid {
      margin-left: 5px;
    }

    &.lg {
      width: auto;
      padding: 0 10px;
      word-break: nowrap;
    }

    &.btn-grid-set {
      color: #46b47f;
      border: 1px solid #46b47f;
      background: #fff;
      box-shadow: 0px 0px 2px 0px rgba(#46b47f, 0.1);

      &:hover {
        color: #3a9b6c;
        background: #f7ebee;
        border: 1px solid #3a9b6c;
      }

      &:active {
        color: #fff;
        border: 1px solid #3a9b6c;
      }
    }

    &.btn-grid-modi {
      color: #c7174c;
      border: 1px solid #c7174c;
      background: #fff;
      box-shadow: 0px 0px 2px 0px rgba(#c7174c, 0.1);

      &:hover {
        color: #a81642;
        background: #f7ebee;
        border: 1px solid #a81642;
      }

      &:active {
        color: #fff;
        border: 1px solid #9c0432;
      }
    }

    &.btn-grid-change {
      color: #566c8a;
      border: 1px solid #566c8a;
      background: #fff;
      box-shadow: 0px 0px 2px 0px rgba(#566c8a, 0.1);

      &:hover {
        color: rgb(52, 62, 73);
        background: #f0f2f3;
        border: 1px solid rgb(73, 84, 97);
      }

      &:active {
        color: #000;
        border: 1px solid #3e444b;
      }

      &.pw-change {
        height: 22px;
        width: 40px;
        margin: 0 auto;
        border: none;
        color: #fff;
        background-color: #566c8a;

        &:hover {
          border: none;
          background-color: rgb(52, 62, 73);
        }

        &:active {
          border: none;
          background-color: #3e444b;
        }
      }
    }

    &.btn-grid-add,
    &.btn-grid-save {
      background: #3693ff;

      &:hover {
        background: #2c84e9;
      }

      &:active {
        background: #2579d8;
      }

      &.charge {
        height: 22px;
      }
    }

    &.btn-grid-delete {
      background: #8e97a3;

      &:hover {
        background: #79838f;
      }

      &:active {
        background: #68717c;
      }
    }

    &.btn-grid-complete {
      color: #2a5eac;
      background: #fff;
      border: 1px solid #2a5eac;
      box-shadow: 0px 0px 2px 0px rgba(#2a5eac, 0.1);

      &:hover {
        color: #244b85;
        background: #f3f8ff;
        border: 1px solid #244b85;
      }

      &:active {
        color: #1d3d6d;
        border: 1px solid #1d3d6d;
      }
    }

    &:hover {
      color: #fff;
    }

    &.btn-grid-new {
      background: #46b47f;

      &:hover {
        background: #3eaa69;
      }

      &:active {
        background: #318b55;
      }
    }
  }

  &.btn-btnonly {
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    background: #3693ff;

    & + .btn-btnonly {
      margin-left: 10px;
    }

    &:hover {
      color: #fff;
      background: #2c84e9;
    }

    &:active {
      color: #fff;
      background: #2579d8;
    }

    &.complete {
      background: #2862d1;

      &:hover {
        color: #fff;
        background: #225ac2;
      }

      &:active {
        color: #fff;
        background: #1a4ead;
      }
    }
  }

  &.btn-postal {
    padding: 0;
    height: 26px;
    font-size: 12px;
    color: #fff;
    background: #3693ff;

    &:hover {
      color: #fff;
      background: #2c84e9;
    }

    &:active {
      color: #fff;
      background: #2579d8;
    }
  }

  &.btn-form-save {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    color: #fff;
    font-size: 16px;
    background: #3693ff;

    &.sm {
      float: right;
      width: 120px;
    }

    &:hover {
      color: #fff;
      background: #2c84e9;
    }

    &:active {
      background: #2579d8;
    }
  }

  &.btn-form-complete {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    color: #fff;
    font-size: 16px;
    background: #565e66;

    &.sm {
      float: right;
      width: 120px;
    }

    &:hover {
      color: #fff;
      background: #424950;
    }

    &:active {
      background: #30363b;
    }
  }

  &.btn-form-delete {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    color: #fff;
    font-size: 16px;
    background: #8e97a3;

    &.sm {
      float: right;
      width: 120px;
    }

    &:hover {
      color: #fff;
      background: #79838f;
    }

    &:active {
      background: #68717c;
    }
  }

  &.btn-connect {
    width: 120px;
    height: 34px;
    padding: 0;
    color: #fff;
    background: #3693ff;

    &:hover {
      color: #fff;
      background: #2c84e9;
    }

    &:active {
      color: #fff;
      background: #2579d8;
    }
  }
}

.btn-line-wrap {
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #f0f0f0;
  }

  .btn-line {
    padding: 0;
    border-bottom: 1px solid #3693ff;
    border-radius: 0 !important;

    a {
      display: inline-block;
      color: #3693ff;
      cursor: pointer;

      &:hover {
        color: #3693ff;
      }
    }
  }
}

.btn-modal {
  width: 80px;

  & + .btn-modal {
    margin-left: 10px;
  }

  &.btn-save {
    height: 35px;
    padding: 0;
    font-size: 14px;
    color: #fff;
    background: #3693ff;

    &:hover {
      color: #fff;
      background: #2c84e9;
    }

    &:active {
      color: #fff;
      background: #2579d8;
    }
  }

  &.btn-select-adjust,
  &.btn-select-clear {
    width: calc(100% - 5px);
    height: 35px;
    padding: 0;
    color: #fff;
  }

  &.btn-select-adjust {
    background: #235a99;

    &:hover {
      color: #fff;
      background: #1a4a81;
    }

    &:active {
      color: #fff;
      background: #143e6e;
    }
  }

  &.btn-select-clear {
    background: #808080;

    &:hover {
      color: #fff;
      background: #6d6d6d;
    }

    &:active {
      color: #fff;
      background: #5e5e5e;
    }
  }

  &.btn-modal-close {
    display: flex;
    width: 30px;
    height: 30px;
    margin-left: 10px;
    border: none;
    background: none;
    align-items: center;
    justify-content: center;
    transition: background ease 0.2s;

    &:hover {
      background: rgb(241, 241, 241);
      border-radius: 4px;
    }
  }

  &.btn-close {
    height: 35px;
    padding: 0;
    font-size: 14px;
    color: #fff;
    background: #b9b9b9;

    &:hover {
      color: #fff;
      background: #929292;
    }

    &:active {
      color: #fff;
      background: #747474;
    }
  }
}

//Modal
.modal-overlay {
  box-sizing: border-box;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;

  &.active {
    display: block;
  }
}

.modal-wrap {
  box-sizing: border-box;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;

  label {
    margin-bottom: 2px;
    font-size: 11px;
    letter-spacing: -1px;

    &.required {
      &::after {
        content: " *";
        font-size: 16px;
        line-height: 0px;
        vertical-align: middle;
        color: #d33a3a;
      }
    }
  }

  &.active {
    display: block;
  }

  .modal-form {
    .modal-form-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: none;
      justify-content: space-between;

      .modal-form-tit {
        display: flex;
        flex-direction: row;
        align-items: center;

        .modal-tit {
          font-size: 20px;
          font-weight: 600;
          letter-spacing: -1px;

          &.font-size {
            font-size: 50px;
          }
        }

        .dx-field {
          width: 80px;

          .dx-texteditor-input-container {
            input {
              text-align: center;
              letter-spacing: -1px;
            }
          }
        }
      }

      .dx-field {
        &.department {
          width: 100px;

          .search-group {
            > div {
              width: 155px;
            }
          }
        }

        &.receive-co {
          display: flex;
          min-width: 200px;
          align-items: center;

          .search-group {
            label {
              width: 45px;
            }

            > div {
              width: 155px;
            }
          }
        }

        .search-group {
          flex-direction: row !important;
          align-items: center;

          label {
            margin-right: 10px;
            margin-bottom: 0;
            font-weight: 600;
            letter-spacing: -1px;
          }
        }
      }
    }

    .input-wrap {
      margin: 20px 0;
      padding: 15px;
      background: #f5f5f6;
      border-radius: 4px;

      .input-grid-tit {
        margin: 20px 0 5px;
        font-size: 14px;
        font-weight: 600;
      }

      .input-grid {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(4, 23.5%);
        grid-gap: 2%;

        &:first-child {
          padding-bottom: 2px;
        }

        & + .input-grid {
          margin-top: 10px;
        }

        &.grid-5 {
          grid-template-columns: repeat(5, 19.2%);
          grid-gap: 1%;
        }

        &.grid-last {
          display: flex;
        }

        &.grid-1 {
          grid-template-columns: 1fr;
          grid-gap: 5px;

          .dx-field {
            display: flex;
            align-items: flex-end;
            gap: 5px;

            &::before {
              display: none;
            }

            &.checkbox-group {
              align-items: center;
              justify-content: flex-start;

              .search-group {
                flex-grow: unset;
              }
            }

            .search-group {
              flex-grow: 1;
              margin-right: 5px;

              .address-input {
                display: grid;
                grid-template-columns: 1fr 1fr 2fr;
                grid-gap: 5px;

                .postal-code {
                  display: grid;
                  grid-template-columns: 3fr 2fr;
                  grid-gap: 5px;
                }
              }
            }
          }
        }

        &.grid-2 {
          grid-template-columns: 1fr 1fr;

          .dx-field {
            display: flex;
            align-items: flex-end;

            .search-group {
              flex-grow: 1;
              margin-right: 5px;
            }
          }
        }

        .dx-field {
          &.postal-code {
            display: grid;
            grid-template-columns: 0 3fr 2fr;
            grid-gap: 5px;
            align-items: flex-end;
          }
        }
      }
    }

    .toolbar-hidden {
      .dx-datagrid {
        .dx-datagrid-header-panel {
          display: none;
        }
      }
    }

    .modal-form-body {
      margin: 20px 0;

      form {
        display: flex;
        .search-group {
          display: flex;
        }
      }

      tr {
        border-left: 1px solid #dddddd;
        border-right: 1px solid #dddddd;
      }

      td {
        border-bottom: 1px solid #dddddd;
      }

      .d-flex {
        & + .d-flex {
          margin-top: 10px;
        }

        .dx-field {
          width: 100%;
        }
      }

      &.body-btn-set {
        display: flex;
        margin: 30px 0 0;
        flex-direction: row;
        justify-content: space-between;
      }

      .dx-datagrid-rowsview {
        &.dx-empty {
          max-height: 35px;
        }
      }
    }

    .dx-field {
      margin-bottom: 0;

      &.row-double {
        grid-column: span 2;
      }

      .search-group {
        flex-direction: column !important;

        label {
          margin-bottom: 2px;
          font-size: 11px;
          letter-spacing: -1px;
        }

        .dx-checkbox-text {
          margin-left: unset;
          letter-spacing: -1px;
        }
      }

      &.checkbox-group {
        display: flex;
        gap: 20px;

        &::before {
          content: none;
        }

        .search-group {
          justify-content: center;
        }
      }

      &.button-line {
        display: flex;
        align-items: center;
      }
    }

    .modal-detail {
      position: relative;

      .modal-detail-tit {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -1px;
        z-index: 10;
      }
    }

    .modal-form-footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 30px;

      &.order-modal {
        justify-content: space-between;
      }
    }

    .dx-datagrid {
      .dx-datagrid-headers {
        background: #90949e;
        border-bottom: none;

        .dx-row {
          td {
            color: #fff;
          }
        }
      }

      .dx-datagrid-rowsview {
        .charge-amount {
          display: table;
          justify-items: right;

          span {
            display: table-row;
            // margin-bottom: 5px;
            margin-right: 5px;
          }
        }
      }

      .dx-command-edit {
        .dx-link-delete {
          width: 45px;
          height: 22px;
          color: #fff;
          font-size: 12px;
          line-height: 22px;
          text-decoration: none;
          white-space: nowrap;
          border-radius: 2px;
          background: #8e97a3;
          letter-spacing: -1px;

          &:hover {
            background: #79838f;
          }

          &:active {
            background: #68717c;
          }
        }
      }
    }
  }
}

.dx-numberbox input {
  text-align: right;
}
